/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div className="layoutWrapper">
        <main>{children}</main>
        <footer className="footerContent">
          <div>
            <img
              aria-hidden="true"
              alt="BF SUPPLY"
              src={require("../images/bfsupplypro.png").default}
              onClick={(event) => {
                event.preventDefault()
                window.scrollTo(0, 0)
              }}
            />
            <p>
              © {new Date().getFullYear()} BF SUPPLY C.A. RIF J-41278019-0
              <br />
              Todos los derechos reservados
            </p>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Layout
